.changes {
  &-container {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    margin: auto 0;
    width: 110rem;
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 4rem 4rem 2.7rem;

    &-img {
      cursor: pointer;
    }
  }

  &-address {
    &-container {
      align-items: center;
      display: flex;
      font-weight: normal;
      padding: 3rem 4rem 17rem;
    }
  }

  &-btn {
    align-self: flex-end;
    margin: 0 4rem 4rem 0;
    margin-top: 0;
    padding: 1.7rem 3rem;
  }
}

.scheduler {
  &-container {
    background-color: $background-color-gray;
    padding: 2rem 4rem;
  }

  &-title {
    font-weight: normal;
    margin-bottom: 2rem;
  }

  &-select {
    appearance: none;
    background-color: $background-color-gray;
    background-image: url("../../img/Expand.svg");
    background-position-x: 100%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    border: 0;
    color: rgba($body-color, 0.5);
    outline: none;
    width: 15rem;

    &-description {
      align-self: center;
      color: rgba($body-color, 0.6);
      margin-top: 2rem;
    }

    &-selected {
      color: var(--app-color);
    }

    &-container {
      border: 2px solid rgba($black, 0.1);
      border-radius: 25px;
      margin-right: 1rem;
      padding: 0.9rem 1.7rem;
    }

    &-font {
      background-color: $background-color-gray;
      background-image: url("../../img/Expand.svg");
      background-position-x: 95%;
      background-position-y: 50%;
      background-repeat: no-repeat;
      background-size: 1.5rem;
      color: var(--app-color);
      width: 25rem;

      &::placeholder {
        color: rgba($body-color, 0.5);
      }
    }

    &-time {
      margin-left: 1rem;
      width: 16rem;
    }

    &-option {
      background-color: $white;
      border-radius: 22.5px;
      box-shadow: 0 4px 10px rgba($black, 0.1);
      color: $body-color;
    }
  }

  &-date {
    align-items: center;
    display: flex;
    font-weight: normal;
  }
}
