// Color system
@use "sass:color";

// Colors
$white: #fff;
$red: #ff6262;
$orange: #f57f20;
$yellow: #ffb800;
$green: #069325;
$green-light: #7ed321;
$blue: #00729d;
$gray: #55595f;
$black: #000;
$background-gray: #f5f5f5;
$background-gray-l2: #e4e6e8;

$yellow-600: color.mix(#000, $yellow, 10%);

$orange-100: color.mix(#fff, $orange, 80%);
$orange-200: color.mix(#fff, $orange, 60%);
$orange-300: color.mix(#fff, $orange, 40%);
$orange-400: color.mix(#fff, $orange, 20%);
$orange-500: $orange;
$orange-600: color.mix(#000, $orange, 20%);
$orange-700: color.mix(#000, $orange, 40%);
$orange-800: color.mix(#000, $orange, 60%);
$orange-900: color.mix(#000, $orange, 80%);

$blue-100: color.mix(#fff, $blue, 80%);
$blue-200: color.mix(#fff, $blue, 60%);
$blue-300: color.mix(#fff, $blue, 40%);
$blue-400: color.mix(#fff, $blue, 20%);
$blue-500: $blue;
$blue-600: color.mix(#000, $blue, 20%);
$blue-700: color.mix(#000, $blue, 40%);
$blue-800: color.mix(#000, $blue, 60%);
$blue-900: color.mix(#000, $blue, 80%);

$light-gray-100: color.mix(#fff, $gray, 90%);
$light-gray-200: color.mix(#fff, $gray, 80%);
$light-gray-300: color.mix(#fff, $gray, 70%);
$light-gray-400: color.mix(#fff, $gray, 60%);
$light-gray-500: color.mix(#fff, $gray, 50%);
$light-gray-600: color.mix(#fff, $gray, 40%);
$light-gray-700: color.mix(#fff, $gray, 30%);
$light-gray-800: color.mix(#fff, $gray, 20%);
$light-gray-900: color.mix(#fff, $gray, 10%);

$background-gray-600: color.mix(#fff, $background-gray, 60%);

// Figma colors and themes
$alert-color: $red;
$primary-color: $blue;
$secondary-color: $orange;
$tertiary-color: #a4aab2;

$background-color-gray: $background-gray;

// Body
$body-bg: $white;
$body-color: $gray;

// Links
$link-color: $gray;
$link-decoration: underline;

// Buttons
$btn-text-color: $gray;

// Typography
$font-family-main: "Avenir", sans-serif;
$font-size-root: 62.5%; // 1rem = 10px
$font-size-base: 1.6rem;

$font-weight-normal: 400;
$font-weight-base: $font-weight-normal;

$line-height-base: 2.6rem;

// Buttons
$primary-hover-color: $blue-600;
$secondary-hover-color: $orange-600;

// Forms and inputs
$label-color: $light-gray-400;
$label-font-size: 1.4rem;
$label-font-weight: 900;
$label-line-height: 3rem;
$label-text-transform: capitalize;

$input-color: $body-color;
$input-font-family: $font-family-main;
$input-font-size: $font-size-base;
$input-font-weight: 900;
$input-line-height: 3rem;
$input-outline: none;

$input-border-color: $light-gray-200;

$input-disabled-color: $light-gray-600;
$input-disabled-cursor: not-allowed;

$input-placeholder-color: $light-gray-400;

// Tables
$table-header-color: $light-gray-400;

// Z-indexes
$z-index-side-bar: 100;
$z-index-header-drop-down: 110;
$z-index-pop-up: 1000;
$z-index-spinner: 1010;
