/* stylelint-disable selector-class-pattern */
.react-datepicker__day--keyboard-selected {
  background-color: var(--app-color);
}

.react-datepicker__day--selected {
  background-color: var(--app-color);
}

.react-datepicker {
  font-family: $font-family-main;
  font-size: 1.2rem;
}

.react-datepicker__current-month {
  font-family: $font-family-main;
  font-size: 1.5rem;
}

.react-datepicker__month-container {
  width: 25rem;
}

.react-datepicker__week,
.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__time-container {
  width: 20rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  font-family: $font-family-main;
  font-size: 2rem;
  width: 100%;
}

.react-datepicker-time__header {
  font-family: $font-family-main;
  font-size: 2rem;
}
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--app-color);

  &:hover {
    background-color: var(--app-color);
  }
}

.react-datepicker__triangle {
  left: 50% !important;
}
/* stylelint-enable */
