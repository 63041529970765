.bcc-table {
  @include respond(phone) {
    display: none;
  }

  &-container {
    &-inner {
      padding: 1rem;
    }

    &-outer {
      background-color: $white;
      border-radius: 10px;
      padding: 2rem;

      @include respond(tab-port) {
        margin: 3rem 1.5rem 2rem;
      }
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;

    &-header,
    .text-label {
      color: $table-header-color;
    }

    &-body,
    .placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-cell {
    display: -webkit-box;
    width: 36rem;
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    /* stylelint-enable */

    @include respond(tab-port) {
      width: 32.8rem;
    }

    @include respond(phone) {
      width: 28.5rem;
    }
  }

  &-bottom-border {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: $light-gray-200;
    padding-bottom: 0.8rem;
  }
}
