.hidden-name {
  /* stylelint-disable */
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  &-1 {
    -webkit-line-clamp: 1;
    max-width: 28rem;

    @include respond(tab-port) {
      max-width: 20rem;
    }
  }
}

.card {
  &-container {
    background-color: $white;
    border-radius: 10px;
    max-width: 38rem;
    padding: 3rem;
    width: 100%;

    @include respond(tab-port) {
      max-width: 36rem;
    }
  }

  &-table {
    &-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: 1.6rem;
      position: relative;
    }

    &-cell {
      &-left {
        margin-left: 0;
      }

      &-right {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  &-table-container {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(3, 38rem);

    @include respond(tab-port) {
      grid-template-columns: repeat(2, 36rem);
      margin: 0 1.5rem;
    }

    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
