.footer {
  &-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    position: absolute;
    width: 100%;
  }

  &-name {
    color: rgba($body-color, 0.4);
    font-family: $font-family-main;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    margin-right: 1rem;
  }
}
