label {
  color: $label-color;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  line-height: $label-line-height;
  text-transform: $label-text-transform;
}

input {
  color: $input-color;
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  outline: $input-outline;

  &:disabled {
    color: $input-disabled-color;
    cursor: $input-disabled-cursor;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}
