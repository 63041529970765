.bread-crumbs {
  &-container {
    display: flex;
    padding-bottom: 2rem;

    @include respond(tab-port) {
      display: none;
    }
  }

  &-element {
    align-items: center;
    display: flex;
    font-weight: 800;

    &:last-child {
      font-weight: 400;
      pointer-events: none;
    }
  }

  &-name {
    color: rgba($body-color, 0.5);
    font-family: $font-family-main;
    font-size: 14px;
    line-height: 19px;
    margin-right: 0.5rem;
    text-decoration: none;

    &-main {
      font-weight: 800;
    }
  }

  &-arrow {
    background: rgba($body-color, 0.5);
    border-radius: 1px;
    clip-path: polygon(0 0, 0 100%, 99% 48%);
    height: 0.8rem;
    margin-right: 0.5rem;
    width: 0.8rem;
  }
}
