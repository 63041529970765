.title-bar {
  align-items: center;
  background: $white;
  border-radius: 10px;
  box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 3.5rem;

  @include respond(tab-port) {
    display: none;
  }

  &-container {
    align-items: center;
    display: flex;

    &-icon {
      height: 3.5rem;
      margin-right: 2rem;
      width: 3.5rem;
    }
  }

  &-button {
    background-color: $white;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    color: $blue;
    display: flex;
    padding: 0.7rem 1.5rem;
    text-decoration: none;

    &-tappable {
      cursor: pointer;
    }

    &-link-icon {
      height: 1.6rem;
      margin-left: 1rem;
      width: 1.6rem;

      path {
        stroke: $blue;
      }
    }
  }
}

.empty {
  &-container {
    align-items: center;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    padding: 3rem 0;
    text-align: center;

    @include respond(tab-port) {
      padding: 5rem 3rem;
    }
    @include respond(phone) {
      padding: 5rem;
    }
  }

  &-title {
    margin-top: 1rem;

    @include respond(tab-port) {
      margin-top: 2.5rem;
    }
  }
}
