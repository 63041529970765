.property-details {
  &-container {
    display: flex;
    max-width: 123rem;
    padding-top: 3rem;

    @include respond(tab-port) {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      width: 100%;
    }
  }

  &-breadcrumbs {
    position: sticky;
    top: 3rem;
  }

  &-floors-container {
    padding-top: 4rem;
    width: 78rem;

    @include respond(tab-port) {
      align-self: center;
      padding-top: 2.5rem;
    }

    @include respond(phone) {
      width: auto;
    }

    &-empty {
      height: 12.65rem;
      width: 12.363rem;
    }
  }

  &-empty-floors-container {
    align-self: center;
    width: 78rem;
  }
}

.property-description {
  &-container {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    height: max-content;
    margin-right: 3rem;
    max-width: 38rem;
    min-width: 38rem;
    padding: 3rem 1.4rem;
    position: sticky;
    top: 7rem;

    @include respond(tab-port) {
      background-color: $background-gray-l2;
      border-radius: 0;
      margin-right: 0;
      max-width: 100%;
      min-width: 100%;
      padding: 1rem 1.4rem;
    }
  }

  &-head {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
    padding: 0 1.6rem;

    @include respond(tab-port) {
      display: none;
    }

    &-img {
      margin-right: 3rem;
      stroke: var(--app-color);

      .property-door {
        fill: var(--app-color);
      }
    }
  }
}

.unit {
  &-card-description {
    white-space: nowrap;
    width: 95%;

    &-container {
      width: 100%;
    }

    &-arrow-svg {
      margin-top: 2.6rem;
    }
  }

  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-arrow {
    &-shape {
      background-color: rgba($black, 0.3);
      clip-path: polygon(15% 0, 100% 50%, 15% 100%, 0 100%, 85% 50%, 0 0);
      content: "";
      height: 1.8rem;
      width: 1.8rem;
    }
  }

  &-link {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-decoration: none;
  }

  &-container {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    height: 10rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    max-width: 38rem;
    min-width: 38rem;
    padding: 1.1rem 2.7rem;
    position: relative;

    @include respond(phone) {
      margin-right: 0;
      max-width: 34.5rem;
      min-width: 34.5rem;
    }
  }

  &-devices {
    display: flex;
    height: 1.5rem;
    margin-bottom: 0.7rem;
    text-transform: uppercase;
  }

  &-status {
    border-radius: 100px;
    padding: 0.3rem 1rem;
    text-transform: uppercase;
    width: fit-content;

    &-occupied {
      background-color: $background-gray-l2;
    }

    &-vacant {
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
    }

    &-not-connected {
      border: 1px solid $alert-color;
      color: $alert-color;
    }

    &-alert {
      background-color: rgba($red, 0.1);
      color: $alert-color;
    }

    &-warning {
      background-color: rgba($yellow, 0.1);
      color: $yellow-600;
    }
  }

  &-line {
    @include alert-side-line;
    height: 8.4rem;

    &-red {
      background: $alert-color;
    }

    &-yellow {
      background: $yellow;
    }
  }
}

.floor {
  &-name {
    margin-bottom: 1.4rem;
  }

  &-units {
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
      display: block;
    }
  }
}

.search {
  &-container {
    background: $background-gray-600;
    border-radius: 10px;
    margin: 3rem 0;
    padding: 1.5rem;

    @include respond(tab-port) {
      margin: 0 0 1rem;
    }
  }

  &-input {
    background-color: $background-gray-600;
    border: none;
    font-weight: 400;
    height: 3.5rem;
    margin: 0 2rem;
    width: 67%;
  }
}

.filter {
  &-name {
    &-selected {
      color: var(--app-color);
    }
  }

  &-number {
    margin-left: 2rem;

    &-selected {
      font-weight: 500;
    }

    &-empty {
      color: $light-gray-500;
    }
  }

  &-container {
    background: $white;
    border-radius: 10px;
    color: $btn-text-color;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1.5rem;
    width: 100%;

    &-selected {
      background: $background-gray-600;
    }

    &-disabled {
      cursor: not-allowed;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-small {
    background-color: $white;
    border-radius: 10px;
    display: none;

    @include respond(tab-port) {
      display: block;
    }
  }

  &-large {
    @include respond(tab-port) {
      display: none;
    }
  }
}

.icon {
  margin-right: 1.5rem;

  &-selected {
    g {
      opacity: 1;
    }

    &-fill {
      path {
        fill: var(--app-color);
      }
    }

    &-stroke {
      path {
        stroke: var(--app-color);
      }
    }
  }
}

.arrow-down {
  margin-left: 2rem;
  transform: rotate(90deg);

  path {
    opacity: 1;
    stroke: $gray;
  }
}
