.dashboard {
  &-empty-img {
    height: 5rem;
    opacity: 0.2;
    width: 6rem;
    @include respond(tab-port) {
      height: 6.8rem;
      width: 8.1rem;
    }
  }

  &-container {
    background: $background-color-gray;
    display: flex;
    justify-content: center;
    padding: 4rem 0 3rem;

    @include respond(tab-port) {
      display: block;
    }
  }

  &-setting {
    align-items: center;
    border-right: 2px solid rgba($body-color, 0.1);
    display: flex;
    flex: 1;
    padding-left: 3rem;

    @include respond(phone) {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 0;
    }

    &:first-child {
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 3rem;

      @include respond(phone) {
        justify-content: center;
        padding-right: 0;
      }
    }

    &:last-child {
      border-right: none;
    }

    &-img {
      height: 3.6rem;
      opacity: 0.2;
      width: 4.3rem;
    }

    &-details {
      margin-left: 2rem;

      @include respond(phone) {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
      }
    }
  }

  &-settings {
    &-container {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      display: flex;
      padding: 3rem 0;
      width: 117.5rem;

      @include respond(tab-port) {
        border-radius: 0;
        width: inherit;
      }
    }
  }
}

.options {
  margin: 3rem 0;
  max-width: 117.5rem;
  padding: 0 1.5rem;

  &-name {
    margin-bottom: 1rem;
  }

  &-properties {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    @include respond(phone) {
      gap: 1rem;
    }
  }
}

.property {
  &-container {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 3rem 3.7rem;
    text-decoration: none;
    width: 38rem;

    @include respond(tab-port) {
      padding: 3rem 3.5rem;
      width: 36.4rem;
    }
    @include respond(phone) {
      width: 34.5rem;
    }

    &-layout {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-text {
      width: 100%;
    }
  }

  &-enter {
    cursor: pointer;
    stroke: var(--app-color);
  }
}
