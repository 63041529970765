/* stylelint-disable selector-class-pattern */
.Toastify__toast {
  background: $body-color;
  border-radius: 5rem;
  color: $white;
  font-size: 2rem;
  font-weight: 800;
  justify-content: center;
  line-height: 2.7rem;
  min-height: initial;

  @include respond(phone) {
    line-height: 1.7rem;
  }

  &-container {
    width: 24rem;

    @include respond(phone) {
      left: auto;
      right: 0;
    }

    &--bottom-right {
      @include respond(phone) {
        bottom: 1em;
      }
    }
  }

  &-body {
    flex: none;
    padding: 1rem 2rem;
  }
}

.Toastify__close-button {
  align-self: center;
  padding: 0.5rem 0 0;

  &--default {
    color: inherit;
    opacity: inherit;
  }

  > svg {
    height: 2rem;
    width: 2rem;
  }
}

.Toastify__progress-bar {
  background: var(--app-color);
}

.Toast__disable-select {
  cursor: auto;
  user-select: none;
}

/* stylelint-enable selector-class-pattern */
