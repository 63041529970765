.tenants {
  &-add-btn {
    &-integrations {
      display: none;
    }

    &-primary {
      margin-bottom: 1.5rem;
      padding: 1.2rem;
      width: 100%;

      &-main {
        background-color: $white;
      }
    }

    &-vacant {
      @include respond(tab-port) {
        background-color: $primary-color;
        color: $white;
      }
    }

    &-container {
      background-color: $white;
      border-radius: 10px;
      margin-top: 1rem;

      &-full-width {
        width: 100%;
      }

      button {
        border: 2px solid $background-gray-l2;
      }

      &-small {
        display: none;
        @include respond(phone) {
          background: linear-gradient(
            180deg,
            rgba(245, 245, 245, 0) 0%,
            $background-gray 34.66%
          );
          bottom: 0;
          display: block;
          left: 0;
          padding: 0 1.5rem 4rem;
          position: fixed;
          width: -webkit-fill-available;
          width: -moz-available;
          z-index: $z-index-header-drop-down;
        }
      }
    }
  }

  &-main-btn-tablet {
    display: none;

    @include respond(tab-port) {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
    }

    @include respond(phone) {
      display: none;
    }
  }

  &-show {
    &-credentials {
      &-red-alert-text-container {
        @include respond(phone) {
          text-align: center;
        }
      }

      &-form {
        display: flex;
        flex-direction: column;
      }

      &-container {
        background: $white;
        border-radius: 5px;
        margin-top: 2.5rem;
        padding: 2rem;

        &-flex {
          display: flex;
          gap: 6rem;
          padding: 3rem;

          @include respond(phone) {
            flex-direction: column;
            gap: 2rem;
          }
        }

        &-outer {
          max-width: 26rem;
        }

        &-inner {
          align-items: center;
          display: flex;
          margin-bottom: 2rem;
        }
      }

      &-label {
        color: $tertiary-color;
      }

      &-btn {
        align-self: flex-end;
        margin-top: 1rem;

        @include respond(phone) {
          align-self: stretch;
        }

        &-container {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          margin: 2.5rem 3rem 3rem;
        }
      }

      &-input {
        border: none;
        border-bottom: 1px solid $light-gray-200;
        margin-bottom: 0.5rem;
        width: 26rem;
        @include respond(phone) {
          width: 100%;
        }
      }

      &-select {
        appearance: none;
        background-color: $white;
        background-image: url("../../img/Expand.svg");
        background-position-x: 93%;
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 1.5rem;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        color: $gray;
        cursor: pointer;
        outline: none;
        padding: 0.7rem 5rem 0.7rem 1.7rem;
        width: 100%;

        &-container {
          margin-bottom: 0.5rem;
          max-width: fit-content;
        }

        &-default {
          color: $orange;
        }
      }
    }

    &-navigation {
      &-container {
        display: flex;
        margin: 3rem 3rem 0;
        @include respond(phone) {
          display: none;
        }

        &-small {
          display: none;
          @include respond(phone) {
            background-color: $background-gray-l2;
            display: flex;
            padding: 2rem;
          }

          &-inner {
            width: 100%;
          }
        }
      }

      &-link {
        background-color: inherit;
        cursor: pointer;
        margin-right: 4rem;
        transition: ease-in-out 0.5s;

        &-selected {
          font-weight: 900;
        }
      }

      &-underline {
        animation: appearing;
        animation-duration: 1s;
        background-color: var(--app-color);
        border-radius: 1rem;
        content: " ";
        height: 0.5rem;
        margin-top: 1rem;

        &-hidden {
          animation: linkUnderlineToTheLeftHidden;
          animation-duration: 1s;
          background-color: var(--app-color);
          border-radius: 1rem;
          content: " ";
          margin-top: 1rem;
        }
      }
    }

    &-ul {
      list-style: disc;
      padding-left: 2rem;
      @include respond(phone) {
        padding-left: 1rem;
      }
    }

    &-li {
      left: 1rem;
      margin-bottom: 1rem;
      padding-left: 1rem;
      position: relative;

      @include respond(phone) {
        padding-left: 0.5rem;
      }
    }

    &-icons {
      height: 5rem;
      width: 5rem;

      &-gear {
        opacity: 0.2;
      }
    }

    &-container {
      background: $background-color-gray;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      margin: auto 3rem;
      width: 110rem;

      @include respond(tab-port) {
        margin: auto 7.5rem;
      }

      @include respond(phone) {
        height: 100%;
        margin: 0;
        overflow: scroll;
        width: 100%;
      }
    }

    &-header {
      align-items: center;
      background-color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 2rem 3rem;

      &-title {
        display: flex;
        font-weight: 400;
        margin-left: 1.5rem;

        &-name {
          @include respond(tab-port) {
            display: none;
          }
        }
      }

      &-settings-icon {
        height: 5rem;
        opacity: 0.6;
        width: 5rem;
      }
    }

    &-description {
      background-color: $white;
      border-radius: 10px;
      padding: 2rem;

      @include respond(phone) {
        padding: 2rem 3rem;
      }

      &-alert {
        align-items: center;
        background: rgba(255, 98, 98, 0.1);
        border: 1px solid $alert-color;
        border-radius: 5px;
        display: flex;
        margin-top: 3rem;
        padding: 2rem 3rem;

        @include respond(phone) {
          flex-direction: column;
          margin-top: 1rem;
        }

        &-title {
          @include respond(phone) {
            text-align: center;
          }
        }

        &-icon {
          height: 5rem;
          margin-right: 2rem;
          width: 5rem;

          @include respond(phone) {
            margin-bottom: 2rem;
            margin-right: 0;
          }
        }
      }

      &-container {
        background-color: $white;
        border-radius: 10px;
        margin: 3rem 0 0;
        padding: 2rem;

        @include respond(phone) {
          margin: 1.5rem 0 0;
        }
      }

      &-body {
        margin-top: 3rem;

        @include respond(phone) {
          margin-top: 1rem;
        }
      }

      &-text-p {
        font-weight: 800;
        margin-bottom: 1rem;
      }

      &-error {
        margin-top: 2rem;
        text-align: right;
        @include respond(phone) {
          text-align: left;
        }
      }

      &-mobile {
        display: none;

        @include respond(phone) {
          display: block;
        }
      }
    }

    &-body {
      background-color: $background-color-gray;
      border-radius: 10px;
      padding: 0 3rem;

      @include respond(phone) {
        padding: 0 1.5rem;
      }
    }

    &-form {
      &-container {
        margin: 3rem 0 0;
        @include respond(phone) {
          margin: 2rem 0 0;
        }
      }

      &-title {
        margin-bottom: 3rem;

        @include respond(phone) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &-modal-container {
    @include respond(tab-port) {
      display: none;
    }
  }

  &-resend {
    &-error {
      &-large {
        margin: 2rem 3rem -1rem 0;

        @include respond(phone) {
          display: none;
        }
      }

      &-small {
        display: none;
        margin: 2rem 1.5rem 0;

        @include respond(phone) {
          display: block;
        }
      }

      &-container {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &-vacant {
    &-container {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
      padding: 3rem 0;

      @include respond(tab-port) {
        margin: 0 1.5rem;
      }
    }

    &-img {
      height: 5rem;
      margin-bottom: 1rem;
      width: 5rem;

      @include respond(tab-port) {
        height: 6.8rem;
        margin-bottom: 2.5rem;
        width: 6.8rem;
      }
    }

    &-text {
      margin: 0.5rem 0 1rem;
      text-align: center;

      @include respond(tab-port) {
        margin: 1rem auto 2.5rem;
        text-align: center;
        width: 47%;
      }

      @include respond(phone) {
        width: 54%;
      }
    }
  }
}

.invitation {
  &-sent {
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 9rem;

      @include respond(tab-port) {
        margin-bottom: 3rem;
      }

      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    &-learn-more-container {
      margin: -7rem 1rem 3rem;

      @include respond(tab-port) {
        margin: -1rem 0 4rem;
      }

      @include respond(phone) {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: -2rem 0 3rem;
      }
    }

    &-btn {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin: 0 3rem 3rem 0;

      @include respond(phone) {
        justify-content: center;
        margin: 0;
      }

      &-tertiary {
        text-decoration: none;
      }

      &-submit {
        @include respond(phone) {
          width: 56%;
        }
      }
    }

    &-checkmark-ul {
      list-style-image: url("../../img/CheckMark.svg");
    }

    &-arrow-ul {
      list-style-image: url("../../img/arrow-right.svg");
    }

    &-column {
      &-container {
        background: $white;
        border-radius: 5px;
        margin-top: 3rem;
        padding: 2rem;
        padding-right: 2.5rem;
        width: 50rem;

        @include respond(tab-port) {
          width: 100%;
        }

        @include respond(phone) {
          margin-top: 1.5rem;
        }

        &-left {
          margin-right: 3rem;

          @include respond(tab-port) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.table {
  &-container {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
    padding: 3rem;

    @include respond(phone) {
      padding: 1rem 3rem 3rem;
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;

    &-header {
      color: $table-header-color;

      &-svg {
        margin-left: 0.5rem;
      }
    }

    &-body {
      font-weight: 900;
      line-height: 3rem;

      &-invite {
        color: $primary-color;
      }
    }

    &-invited-name {
      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &-cell {
    margin-bottom: 0;
    margin-right: 1rem;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 24rem;

    &-tablet {
      @include respond(tab-port) {
        width: 20rem;
      }
    }

    &-hidden-small-screens {
      @include respond(tab-port) {
        display: none;
      }
    }

    &-very-small {
      align-items: baseline;
      width: 3%;
    }

    &-trash {
      background-color: $white;
      cursor: pointer;
    }

    &:hover {
      overflow: visible;
      white-space: normal;
    }

    &-date {
      margin-right: auto;
      width: auto;
    }

    &:nth-child(3) {
      margin-right: auto;
      width: auto;
      @include respond(tab-port) {
        width: 18.5rem;
      }
    }
    // Disabling eslint to keep correct ordering
    /* stylelint-disable */
    @include respond(tab-port) {
      margin-bottom: 1rem;
    }
    @include respond(phone) {
      margin-bottom: 1rem;
    }
    &-entry {
      margin-bottom: 0;
    }
    /* stylelint-enable */
  }
}

.table-responsive {
  .table-cell {
    @include respond(tab-port) {
      width: 19.5rem;
    }
  }

  .table-cell-very-small {
    width: 4%;
  }
}

.tenants-show-move-out-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;

  &-new-flow {
    @include respond(tab-port) {
      width: 100%;
    }
  }
}

.user-card {
  &-action-btn {
    margin-left: 1.5rem;

    @include respond(phone) {
      background-color: $white;
      margin-bottom: 1.5rem;
      margin-left: 0;
      width: 100%;
    }

    &-container {
      display: flex;
      justify-content: flex-end;
      margin: 2.5rem 3rem 0;

      @include respond(phone) {
        flex-direction: column-reverse;
        margin: 2rem 1.5rem 0;
      }
    }

    &-resend {
      background-color: $primary-color;
      color: $white;

      @include respond(phone) {
        background-color: $primary-color;
      }
    }
  }

  &-container {
    background: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
    display: none;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 3rem;

    @include respond(phone) {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &-entry {
    display: flex;
    flex-direction: column;

    &-container {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-header {
      color: $table-header-color;
      margin-bottom: 1rem;
    }

    &-blue {
      color: $blue;
    }

    &-red {
      color: $red;
    }

    &-body {
      overflow-wrap: anywhere;
    }
  }
}

.entrata-banner {
  &-container {
    background-color: $blue;
    padding: 1.3rem 4rem;

    @include respond(phone) {
      flex-direction: column;
      padding: 1.5rem;
    }

    &-inner {
      @include respond(phone) {
        flex-direction: column;
      }
    }
  }

  &-text {
    color: $white;
    margin-left: 2rem;

    @include respond(phone) {
      margin: 1rem 0;
    }
  }

  &-icon {
    path {
      fill: $white;
    }
  }

  &-btn {
    background-color: inherit;
    border: 1px solid $white;
    padding: 0.2rem 1.2rem;
    text-decoration: none;
  }
}
