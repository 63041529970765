.header {
  align-items: center;
  background: $white;
  border-radius: 10px;
  box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
  display: flex;
  padding: 3rem;

  @include respond(tab-port) {
    display: none;
  }

  &-icon {
    height: 5rem;
    margin-right: 2rem;
    opacity: 0.5;
    width: 5rem;

    path {
      stroke: $light-gray-400;
    }
  }

  &-description {
    margin: 3rem 0 2rem;

    @include respond(tab-port) {
      margin: 3rem 1.5rem 2rem;
    }
  }
}
