.header-container {
  background-color: $white;
  justify-content: space-between;
  min-height: 8.7rem;
  position: relative;

  &-large {
    align-items: stretch;
    display: flex;
    padding: 0 3rem;

    @include respond(tab-port) {
      display: none;
    }
  }

  &-small {
    align-items: center;
    display: none;
    padding: 0 2rem 0 1.4rem;

    @include respond(tab-port) {
      display: flex;
    }

    @include respond(phone) {
      position: sticky;
      right: 0;
      top: 0;
      z-index: $z-index-header-drop-down;
    }
  }
}

.header {
  &-back {
    transform: rotate(180deg);

    path {
      opacity: 1;
      stroke: $gray;
    }
  }

  &-bell {
    &-container {
      position: relative;
    }
  }

  &-dropdown {
    &-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 6rem;
      top: 6rem;
      z-index: $z-index-header-drop-down;
    }

    &-arrow {
      background: $primary-color;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      float: right;
      height: 0.8rem;
      margin-right: 2.9rem;
      width: 1.4rem;
    }

    &-items {
      background: $primary-color;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem;
    }

    &-button {
      background: transparent;
      border-bottom: 1px solid rgba($white, 0.5);
      color: $white;
      cursor: pointer;
      font-size: 1.6rem;
      font-weight: 800;
      padding: 1rem 0;

      &:last-child {
        border-bottom: none;
      }

      &-link {
        align-self: center;
        text-decoration: none;
      }
    }
  }

  &-name {
    align-self: center;
    background-color: $white;
    border: none;
    color: $body-color;
    cursor: pointer;
    font-family: $font-family-main;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.9rem;
    outline: none;
  }

  &-menu {
    align-items: stretch;
    display: flex;
    flex-grow: 0.07;
    font-size: 2rem;
    font-weight: 800;
    justify-content: space-between;
    line-height: 2.7rem;

    &-link {
      align-items: center;
      color: rgba($body-color, 0.3);
      display: flex;
      justify-content: center;
      text-decoration: none;
      transition: ease-in-out 0.3s;
    }

    &-selected {
      border-bottom: 3px solid var(--app-color);
      color: inherit;
    }
  }

  &-logo {
    align-self: center;
    image-rendering: -webkit-optimize-contrast;
  }

  &-alert {
    background-color: $white;
    cursor: pointer;
    height: 2.4rem;
    margin-left: 3rem;
    width: 2.4rem;

    @include respond(tab-port) {
      margin-left: 0;
    }

    &-number {
      background-color: var(--app-color);
      border-radius: 0.9rem;
      color: $white;
      cursor: pointer;
      display: flex;
      height: 1.8rem;
      justify-content: center;
      left: 1.3rem;
      min-width: 1.8rem;
      padding: 0 0.5rem;
      position: absolute;
      top: -0.7rem;
      width: fit-content;
    }
  }

  &-close {
    .close-test {
      opacity: 0.7;
    }
  }
}

.invite-manager-modal {
  background: $background-gray;
  border-radius: 1rem;
  margin: auto 0;
  width: 100rem;

  @include respond(tab-port) {
    width: 61rem;
  }

  @include respond(phone) {
    height: 100%;
    width: 100%;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    @include respond(phone) {
      background-color: $white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      justify-content: center;
      margin-top: -1rem;
      padding: 2rem 2rem 4rem;
    }

    &-submit {
      @include respond(phone) {
        width: 65%;
      }
    }
  }

  &-header {
    align-items: center;
    background-color: $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    @include respond(phone) {
      padding: 1rem 2rem;
    }

    &-svg {
      height: 5rem;
      opacity: 0.6;
      stroke: $gray;
      width: 5rem;
    }

    &-title {
      padding: 3rem 2rem;
    }
  }

  &-title {
    font-size: 2.4rem;
    font-weight: 800;
    padding: 3rem 2rem;
  }

  &-body {
    background: $background-color-gray;
    border-radius: 0 0 1rem 1rem;
    padding: 3rem;

    @include respond(phone) {
      padding: 2.5rem 1.5rem;
    }
  }

  &-heading {
    @extend %menu;
    margin-bottom: 1.5rem;

    @include respond(phone) {
      display: none;
    }
  }

  &-submit-btn {
    padding: 1rem;
  }

  &-section {
    background: $white;
    border-radius: 0.5rem;
    margin-bottom: 3rem;
    padding: 2rem;

    @include respond(phone) {
      margin-bottom: 0;
      margin-top: -1rem;
      padding-bottom: 0;
      padding-top: 3rem;
    }

    input {
      width: 43rem;

      @include respond(phone) {
        width: 100%;
      }
    }
  }
}

.invite-tenant-modal {
  &-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-top: 2rem;
    @include respond(phone) {
      flex-direction: column-reverse;
      margin-top: 2rem;
    }

    &-submit {
      margin-left: 1rem;
      @include respond(phone) {
        margin-bottom: 1.5rem;
        margin-left: 0;
      }
    }
  }

  &-section {
    background: $white;
    border-radius: 0.5rem;
    margin-bottom: 3rem;
    padding: 2rem;
    @include respond(phone) {
      margin-bottom: 0;
    }

    input {
      width: 43rem;

      @include respond(phone) {
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }
  }
}

.side-menu {
  &-container {
    display: flex;
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-pop-up;
  }

  &-left {
    background-color: $blue;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: inherit;
    justify-content: space-between;
    max-width: 30rem;
  }

  &-right {
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.5);
    flex-grow: 2;
    height: inherit;
  }

  &-logo {
    path {
      fill: $white;
    }
  }

  &-close {
    height: 2rem;
    margin-right: 6rem;
    width: 2rem;

    path {
      fill: $white;
      opacity: 1;
    }
  }

  &-header-container {
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    margin: 0 1.5rem;
    padding: 2rem 0 3rem 0.5rem;
  }

  &-link {
    color: $white;
    display: flex;
    font-weight: 500;
    justify-content: center;
    padding: 1rem 4rem;
    text-decoration: none;

    &-container {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding: 2rem 0;
      text-align: center;
      width: 90%;
    }

    &-large {
      min-width: 30rem;
    }
  }

  &-selected {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #00729d;
    border-radius: 10px;
  }

  &-user-info {
    &-container {
      align-items: center;
      border-top: 2px solid hsla(0, 0%, 0%, 0.1);
      display: flex;
      justify-content: space-around;
      margin: 0 2rem;
      padding: 2rem 0 3rem;
    }

    &-img {
      svg {
        path {
          stroke: $white;
        }
      }
    }

    &-name {
      color: $white;
      font-weight: 500;
      margin-left: 1rem;
    }
  }
}

.show-password {
  &-button {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 0%;
  }

  &-container {
    position: relative;
  }
}

.backup-wifi-network-modal-section {
  background: $white;
  border-radius: 5px;
  margin-bottom: 3rem;
  padding: 2rem;

  @include respond(phone) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.network-type {
  &-fields {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-selection {
    width: 50%;
  }

  &-select {
    appearance: none;
    background-color: $white;
    background-image: url("../../img/Expand.svg");
    background-position-x: 100%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    border: 0;
    color: $gray;
    outline: none;
    width: 100%;

    @include respond(phone) {
      font-size: 1.6rem;
      font-weight: 900;
    }

    &-container {
      border: 2px solid rgba($black, 0.1);
      border-radius: 25px;
      margin-bottom: 2rem;
      margin-right: 1rem;
      max-width: 20rem;
      padding: 0.7rem 1.7rem;
    }
  }
}

.building {
  &-select {
    appearance: none;
    background-color: $background-gray;
    background-image: url("../../img/Expand.svg");
    background-position-x: 93%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    border: 0;
    border-radius: 50%;
    color: $gray;
    cursor: pointer;
    outline: none;
    padding: 0.7rem 4rem 0.7rem 1.7rem;
    width: 100%;

    &-container {
      margin: 3rem 0;
      max-width: fit-content;
      padding: 0;
    }
  }
}
