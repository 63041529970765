.unit {
  &-empty-icon {
    @include respond(tab-port) {
      height: 5rem;
      width: 6.5rem;
    }

    &-integrations {
      opacity: 0.4;

      @include respond(tab-port) {
        height: 6.8rem;
        width: 6.8rem;
      }
    }
  }

  &-alert {
    &-call-to-action {
      background: none;
      border: 0.1rem solid $white;
      border-radius: 5rem;
      color: $white;
      padding: 0.2rem 1.2rem;
    }

    &-container {
      background-color: $alert-color;
      cursor: pointer;
      height: 100%;
      margin-bottom: 0.2rem;
      width: 100%;

      &-reconfiguration {
        background-color: $blue;
        cursor: pointer;
        height: 100%;
        margin-bottom: 0.2rem;
        width: 100%;
      }
    }

    &-icon {
      &-svg {
        height: 2.3rem;
        width: 2.3rem;

        path {
          fill: $white;
        }
      }
    }

    &-text {
      color: $white;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
      text-align: start;
    }
  }

  &-configurations {
    &-container {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      cursor: pointer;
      margin-bottom: 2rem;
      padding: 0 4rem;
    }

    &-activity {
      align-items: center;
      display: flex;
      padding: 2rem 0;

      &:not(:last-child) {
        border-bottom: 2px solid rgba($body-color, 0.1);
      }

      &-pencil {
        fill: var(--app-color);
        margin-left: 1rem;
      }
    }

    &-btn {
      align-self: flex-end;
      bottom: 5rem;
      padding: 1.6rem 2.2rem;
      position: fixed;
      right: 2.4rem;
    }
  }

  &-main-container {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    margin: 3rem 0;
    max-width: 118rem;
    padding: 1.2rem 3.5rem;
    position: relative;

    @include respond(tab-port) {
      display: none;
    }

    &-line {
      @include alert-side-line;
      height: 8.7rem;
    }

    &-small {
      display: none;

      @include respond(tab-port) {
        background-color: $white;
        display: block;
        margin-bottom: 2rem;
      }
    }
  }

  &-details-main-container {
    padding-top: 3rem;
    width: 118rem;

    @include respond(tab-port) {
      width: 100%;
    }

    &-small {
      @include respond(tab-port) {
        width: 100%;
      }
    }
  }

  &-names {
    align-items: center;
    display: flex;
    min-width: 38rem;

    &-img {
      margin-top: 2rem;
      stroke: var(--app-color);
    }

    &-info {
      margin-left: 2.5rem;

      &-name {
        margin-right: 0.8rem;
      }
    }
  }

  &-details {
    align-items: center;
    display: flex;
    margin-right: 8rem;

    @include respond(tab-port) {
      background-color: transparent;
      flex: 2;
      flex-direction: column;
      margin: 0 1rem;
      opacity: 0.5;
      padding: 2rem 0;
    }

    &-selected {
      border-bottom: 3px solid var(--app-color);
      color: inherit;
      opacity: 1;
    }

    &-container {
      display: flex;
      flex: 2;

      @include respond(tab-port) {
        flex: auto;
        justify-content: space-around;
      }

      &-right {
        display: flex;
        padding-top: 2rem;
        width: 100%;
      }
    }

    &-number {
      margin-right: 1rem;

      @include respond(tab-port) {
        margin-right: 0;
      }
    }

    &-button {
      align-self: center;
    }
  }
}

.brilliant {
  &-container {
    @include respond(tab-port) {
      display: none;
    }

    &-small-padding {
      @include respond(phone) {
        padding: 0 2rem;
      }

      @include respond(phone) {
        padding: 0 1.5rem;
      }
    }

    &-name {
      margin-bottom: 2rem;

      @include respond(tab-port) {
        width: 35rem;
      }

      @include respond(phone) {
        width: 34rem;
      }
    }

    &-cards {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      @include respond(phone) {
        align-items: center;
        flex-direction: column;
      }
    }

    &-small {
      display: none;

      @include respond(tab-port) {
        display: block;
        margin: auto;
        width: 95%;
      }

      @include respond(phone) {
        width: 100%;
      }

      &-no-margins {
        margin: 0;
        width: 100%;
      }
    }

    &-integration-link {
      margin-bottom: 2rem;
    }

    &-residents {
      margin: 0;

      @include respond(tab-port) {
        margin: 0 1.5rem;
      }

      @include respond(phone) {
        margin: 0;
      }
    }
  }

  &-card {
    &-img {
      &-left {
        margin-right: 2rem;

        &-fill {
          fill: var(--app-color);
        }

        &-stroke {
          stroke: var(--app-color);
        }
      }
    }

    &-container {
      align-items: center;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      display: flex;
      margin-bottom: 2rem;
      margin-right: 0;
      padding: 3rem;
      width: 38rem;

      @include respond(tab-port) {
        margin-right: 2rem;
        padding: 2.5rem;
        width: 35rem;
      }

      @include respond(phone) {
        margin-bottom: 1rem;
        margin-right: 0;
        padding: 2rem;
        width: 34rem;
      }

      &:not(:nth-child(3n)) {
        margin-right: 2rem;

        @include respond(phone) {
          margin-right: 0;
        }
      }
    }

    &-details {
      &-container {
        align-self: baseline;
        width: 100%;

        @include respond(phone) {
          height: 90%;
        }
      }

      &-description {
        &-container {
          align-items: center;
          background-color: $white;
          border-radius: 10px;
          box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
          display: flex;
          gap: 3rem;
          margin-bottom: 2rem;
          margin-right: 0;
          padding: 3rem;
          width: 100%;
        }
      }
    }

    &-description {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      width: 0;

      &-type {
        opacity: 0.6;
        padding-bottom: 3px;
      }

      &-id {
        opacity: 0.6;
        padding-bottom: 3px;
      }

      &-reconfiguring {
        color: $blue;
        font-weight: 500;
      }
    }

    &-installation {
      cursor: pointer;
      height: 10.7rem;
      padding: 2rem 3rem;

      &-icon {
        height: 3rem;
        margin-left: 2rem;
        width: 3rem;
      }

      &-error {
        color: $alert-color;
        font-weight: 500;
      }
    }
  }
}

.resident {
  &-vacant {
    &-container {
      background: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
      margin: 1.5rem 1.5rem 0;
      padding: 7rem 0 6rem;
    }

    &-img {
      height: 5.6rem;
      width: 5.3rem;

      g {
        opacity: 1;
      }

      path {
        fill: $background-gray-l2;
      }
    }

    &-body {
      margin-bottom: 5.5rem;
      margin-top: 3rem;
      max-width: 22rem;
      text-align: center;
    }
  }
}
