.login-container {
  padding: 10rem 0 5rem;

  @include respond(tab-port) {
    padding: 1rem;
  }
}

.background-container {
  align-items: center;
  background: linear-gradient(
      rgba($primary-color, 0.9),
      rgba($primary-color, 0.9)
    ),
    url("../../img/Hero Image.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  &-vertical_split {
    align-items: initial;
    background-position-x: -200px;
    justify-content: space-between;
  }

  &-user {
    background: linear-gradient(
        rgba($primary-color, 0.9),
        rgba($primary-color, 0.9)
      ),
      url("../../img/HeroUserSmall.png");
    @include respond(tab-port) {
      background-position-y: center;
    }
    @include respond(phone) {
      background-position-x: 14%;
      background-position-y: bottom;
    }
  }
}

.inner-container {
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 55rem;
  padding: 2rem 6rem 4rem;

  @include respond(tab-port) {
    max-width: 35rem;
    padding: 2rem 4rem 4rem;
  }
}

.logo {
  margin: 3.5rem;
}

.login {
  &-name {
    margin: -1rem 0 1.5rem;
    text-align: center;
  }

  &-description {
    margin-bottom: 4rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 100%;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
    }

    &-input {
      border: 2px solid rgba($black, 0.1);
      border-radius: 100px;
      box-sizing: border-box;
      padding: 1.4rem;
      width: 100%;

      &-error {
        border-color: $alert-color;
        color: $alert-color;

        &-message {
          color: $alert-color;
          padding: 0 1.5rem;
        }
      }
    }

    &-btn {
      margin: 1rem 0;
      padding: 1.5rem 0;
    }

    &-password {
      &-container {
        position: relative;
      }

      &-icon {
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 25%;
      }
    }
  }

  &-reset {
    margin-bottom: 1.5rem;
  }

  &-forbidden {
    &-container {
      margin-top: 5rem;
      padding: 0 3rem;
      text-align: center;
    }

    &-link {
      margin-top: 8rem;
    }

    &-line {
      border-top: 2px solid rgba($body-color, 0.1);
      margin: 4rem 0;
      width: 25.95rem;
    }
  }
}
