/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
.remote-lock {
  &-cell-container {
    align-items: center;
    background-color: #f9f9f9;
    cursor: pointer;
    display: flex;
    height: 6rem;
    max-width: 46rem;
    min-width: 46rem;
    opacity: 1;
    padding: 1.2rem 1.6rem;

    &:hover .remote-lock-table-assign-devices {
      background-color: $primary-color;
      color: $white;
    }

    &:hover .remote-lock-table-assign-devices-green {
      background-color: $green;
      color: $white;
    }

    &:hover .remote-lock-table-cell-add {
      background-color: $primary-color;
      border-radius: 50px;
      color: $white;
      display: block;
      height: 3rem;
      margin-right: 0.5rem;
      padding: 0.5rem;
      text-align: center;
      width: 3rem;
    }

    &:hover .remote-lock-table-cell-add-green {
      background-color: $green;
    }

    &-small {
      cursor: auto;
      max-width: 21rem;
      min-width: 21rem;
    }
  }

  &-login-body-text {
    margin-bottom: 5rem;
    margin-top: 3rem;
    text-align: center;
  }

  &-container {
    max-width: 118rem;
    padding-top: 3rem;
  }

  &-building-name {
    @include text;
    background-color: transparent;
    cursor: pointer;
    margin: 2rem 0;
    margin-right: 4rem;

    &-selected {
      font-weight: 900;

      &::after {
        background-color: var(--app-color);
        border-radius: 10px;
        content: "";
        display: block;
        height: 0.5rem;
        margin-top: 1.3rem;
        width: 100%;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &-header {
    &-container {
      background: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba(172, 176, 187, 0.1);
      display: flex;
      justify-content: space-between;
      padding: 3rem;
      width: 118rem;
    }

    &-svg {
      margin-right: 4rem;
    }
  }

  &-selected {
    &-container {
      align-items: center;
      background: $white;
      border-radius: 50px;
      bottom: 3rem;
      box-shadow: 0 10px 34px rgba(172, 176, 187, 0.5);
      display: flex;
      left: 50%;
      padding: 1rem 3rem;
      position: fixed;
      transform: translateX(-50%);
      z-index: $z-index-header-drop-down;
    }

    &-amount {
      align-items: center;
      display: flex;
      text-transform: uppercase;

      &-line {
        background-color: rgba(0, 0, 0, 0.44);
        height: 6rem;
        margin: 0 2.4rem;
        width: 1px;
      }

      &-number {
        color: $orange;
        margin-right: 1rem;
      }

      &-text {
        opacity: 0.7;
      }
    }

    &-close-icon {
      cursor: pointer;
      height: 2.9rem;
      width: 2.9rem;

      path {
        opacity: 0.5;
      }
    }
  }

  &-table {
    &-cell-add {
      display: none;
    }

    &-clock {
      margin-right: 2rem;
    }

    &-dropdown {
      &-container {
        animation: appearing;
        animation-duration: 0.3s;
        background: $white;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 17px;
        cursor: default;
        filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
        left: 3%;
        padding: 1rem;
        position: absolute;
        top: 1rem;
        width: 94%;
        z-index: 100;
      }

      &-checkmark {
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        display: inline-block;
        height: 1.3rem;
        transform: rotate(45deg);
        width: 0.8rem;

        &-container {
          background-color: $blue;
          border-radius: 50%;
          cursor: pointer;
          display: inline-block;
          height: 3rem;
          position: absolute;
          right: 1rem;
          top: 1rem;
          width: 3rem;

          &-green {
            background-color: $green;
          }
        }
      }

      &-available-device {
        background-color: transparent;
        cursor: pointer;
        display: block;
        margin-bottom: 1rem;
        text-align: left;

        &:hover {
          color: var(--app-color);
        }

        &-empty {
          align-items: center;
          display: flex;
          height: inherit;
          justify-content: center;
          opacity: 0.5;
          padding: 4rem;
        }

        &-container {
          height: 15rem;
          margin-left: 1rem;
          overflow-y: scroll;
          scrollbar-color: $orange $background-gray;
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background: $background-gray;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $orange;
            border-radius: 20px;
          }
        }
      }

      &-search {
        &-box {
          align-items: center;
          background-color: $background-gray;
          margin: 0 -1rem 1rem;
          padding: 1rem 0.8rem;
        }

        &-container {
          background-color: $white;
          border-radius: 20px;
          height: 4rem;
          padding: 1.3rem;
        }

        &-input {
          border: none;
          font-weight: 400;
          margin: 0 2rem;
          width: 100%;
        }
      }

      &-devices {
        &-container {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          width: 38rem;
        }

        &-checkmark-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &-container {
      background-color: $white;
      border-radius: 10px;
      padding: 2rem;
    }

    &-device {
      &-number {
        background: rgba(42, 126, 163, 0.1);
        border-radius: 15px;
        color: $blue;
        padding: 0.6rem 1rem;
      }

      &-name {
        max-width: 8.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-cell {
          max-width: 9.3rem;
        }

        &-container {
          align-items: center;
          background: rgba(42, 126, 163, 0.1);
          border-radius: 15px;
          color: $blue;
          cursor: auto;
          display: flex;
          margin-right: 0.5rem;
          padding: 0.6rem 1rem;

          &-green {
            border-color: $green;
            color: $green;
          }

          &-small {
            margin-right: 0;
          }
        }

        &-icon {
          cursor: pointer;
          height: 1.3rem;
          margin-left: 0.5rem;
          padding: 0.2rem;
          width: 1.3rem;

          &:hover {
            background-color: $white;
            border-radius: 10px;
          }
        }

        &-large {
          margin-bottom: 0.5rem;
        }
      }

      &-empty {
        opacity: 0.5;
        padding: 0.7rem 0.5rem;
      }
    }

    &-assign-devices {
      width: fit-content;

      &-green {
        border-color: $green;
        color: $green;
      }
    }

    &-unit {
      color: $body-color;
      max-width: 12rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-disabled {
        background-color: $white;
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;

        &:hover {
          background-color: $white;
          color: $primary-color;
        }

        &-changes-in-progress {
          background-color: $background-gray;
        }
      }
    }

    &-checkbox {
      appearance: none;
      background-color: $white;
      border: 2px solid $light-gray-200;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      height: 2.4rem;
      margin-right: 1.6rem;
      position: relative;
      width: 2.4rem;

      &:checked {
        border-color: $secondary-color;
        color: $secondary-color;

        &::after {
          content: "\2713";
          font-size: 1.5rem;
          left: 4px;
          position: absolute;
          top: -3px;
        }
      }

      &:hover {
        border: 2px solid $orange-300;
      }

      .MuiSvgIcon-root {
        font-size: 3rem;
      }

      .MuiCheckbox-root {
        color: rgb(85, 89, 95);
        margin-right: 1.5rem;
        padding: 0;
      }
    }
  }

  &-logo {
    height: 4.6rem;
    width: 20rem;
  }
}

.center-align-tooltip-as-row {
  align-items: center;
  display: flex;
}

.tooltip .tooltiptext {
  background-color: $white;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  bottom: 3.5rem;
  color: $body-color;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  left: 30%;
  margin-left: -10.5rem;
  padding: 1.5rem;

  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 21rem;

  &-small {
    bottom: 150%;
    left: 50%;
    margin-left: 0;
    transform: translate(-50%);
    white-space: nowrap;
    width: fit-content;
    z-index: $z-index-side-bar;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  background-color: $white;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-right-style: solid;
  border-right-width: 2px;
  content: " ";
  display: inline-block;
  height: 1.5rem;
  left: 50.5%;
  margin-left: -2px;
  position: absolute;
  top: 94%;
  transform: rotate(45deg);
  width: 1.5rem;
}

.tooltip .tooltiptext-small::after {
  left: 41.5%;
  top: 88%;
  transform: rotate(45deg);
}

.selected-unit-access-devices {
  background-color: #f9f9f9;
  opacity: 1;
}

tr:hover {
  .remote-lock-cell-container {
    background-color: $background-gray;
  }

  .selected-unit-access-devices {
    background-color: $background-gray;
  }
}

tr:hover {
  .unselected-unit-name {
    background-color: $background-gray-600;
  }
}

.unselected-unit-name {
  background-color: $white;
}