.page {
  &-container {
    padding-top: 3rem;

    @include respond(tab-port) {
      padding-top: 0;
    }

    &-inner {
      width: 118rem;
    }
  }
}
