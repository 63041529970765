.side-bar {
  &-container {
    background: $background-color-gray;
    border-left: 2px solid rgba($body-color, 0.1);
    box-shadow: 1px 0 0 $white;
    height: 100%;
    min-width: 33rem;
    overflow: scroll;
    overflow: -webkit-paged-y;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-side-bar;

    @include respond(phone) {
      height: fit-content;
      top: 0;
      width: 100%;
      z-index: $z-index-pop-up;
    }
  }

  &-building {
    text-transform: uppercase;
  }

  &-dropdown-arrow {
    background: $background-color-gray;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    height: 1.5rem;
    margin: -1.5rem 3rem 0 26.5rem;
    position: absolute;
    width: 4rem;
  }

  &-empty {
    min-height: 100vh;

    &-bell {
      &-container {
        margin: auto 0;
      }

      &-svg {
        height: 9.4rem;
        margin-bottom: 3rem;
        width: 9.4rem;

        path {
          opacity: 0.1;
        }
      }

      &-message {
        color: rgba($body-color, 0.3);
      }
    }
  }

  &-header {
    background: rgba($body-color, 0.1);
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    margin: 3rem 0;
    padding: 2rem 11rem;

    @include respond(phone) {
      align-items: center;
      background: $white;
      border-radius: 0;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
      display: flex;
      margin: 0;
      margin-bottom: 2.5rem;
      min-height: 8.7rem;
      padding: 0 2rem;
      width: 100%;
    }

    &-close {
      @include respond(phone) {
        align-items: center;
        display: flex;
      }

      &-btn {
        background: none;
        display: none;

        @include respond(phone) {
          display: block;
        }
      }

      &-title {
        @include respond(phone) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.alert-card {
  &-container {
    background-color: $white;
    border-radius: 10px;
    color: $body-color;
    cursor: pointer;
    margin-top: 1rem;
    padding: 1rem;
    text-decoration: none;
    width: 27rem;

    &:hover {
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.5);
    }

    &:last-child {
      margin-bottom: 3rem;
    }
  }

  &-red-line {
    background: $alert-color;
    border-radius: 10px;
    height: 8rem;
    margin-right: 1.7rem;
    width: 0.4rem;
  }

  &-description {
    width: 100%;

    &-svg {
      width: 3rem;

      &-tenant {
        height: 3rem;
        width: 3rem;
      }

      &-low-battery {
        height: 3rem;
        opacity: 0.2;
        width: 3rem;
      }

      &-offline-device {
        height: 3rem;
        width: 3rem;

        path {
          fill: $black;
          opacity: 0.2;
        }
      }
    }
  }

  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.alert-popup {
  &-container {
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    margin: auto;
    width: 50rem;
  }

  &-title {
    &-container {
      align-items: center;
      background-color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 3.5rem 4rem;
    }

    &-close {
      cursor: pointer;
    }
  }

  &-description {
    &-container {
      background-color: $background-color-gray;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 4.8rem 0 3rem;
    }

    &-title {
      font-weight: 800;
      margin-left: 1rem;
    }

    &-text {
      margin: 2.8rem 4rem 3.9rem;
      text-align: center;
    }

    &-svg {
      g {
        opacity: 1;
      }

      .p-stroke {
        stroke: var(--app-color);
      }

      .p-fill {
        fill: var(--app-color);
      }

      &-leak {
        height: 5.5rem;
        width: 5.5rem;
      }

      &-extreme-temperature {
        height: 6rem;
        width: 3.8rem;
      }

      &-failed-invite {
        height: 6rem;
        width: 6rem;
      }

      &-reconfiguration {
        height: 6rem;
        width: 8rem;
      }

      &-low-battery {
        height: 4rem;
        width: 6.6rem;
      }

      &-offline-device {
        height: 6rem;
        width: 8rem;
      }
    }

    &-btn {
      padding: 1.6rem 2.2rem;
      text-decoration: none;
    }
  }
}
