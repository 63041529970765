// Media query manager

/*
Screen width  | Size            | $breakpoint arg
--------------+-----------------+----------------
0 - 768px     | Phone           | phone
768 - 1184x   | Tablet portrait | tab-port
1184 - 1800px | Desktop         |
1800px -      | Big desktop     | big-desktop

1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 48em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 74em) {
      @content;
    } // 1184px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}

@mixin text {
  color: $body-color;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.6rem;
}

@mixin alert-side-line {
  border-radius: 10px;
  left: 0.8rem;
  position: absolute;
  top: 0.8rem;
  width: 0.4rem;
}
