.unit {
  &-title {
    font-size: 2.4rem;
    font-weight: 800;
    line-height: 3.3rem;
  }

  &-description {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.9rem;

    &-small {
      font-size: 1.2rem;
      line-height: 1.6rem;

      &-info {
        text-transform: uppercase;
      }
    }
  }

  &-tag {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.4rem;
  }

  &-device-id {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
  }
}

%menu,
.menu {
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.7rem;

  &-light {
    font-weight: 400;
  }
}

.sub-menu {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.5rem;

  &-selected {
    font-weight: 800;
  }
}

.notification-counter {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8rem;
}

.tool-tip {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.2rem;
}

.sub-heading {
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.9rem;
}

.large-title {
  font-size: 5rem;
  font-weight: 300;
  line-height: 7rem;
}

h1 {
  font-size: 4.4rem;
  font-weight: 500;
  line-height: 6rem;
}

h2 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.8rem;
}

h3 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 4.1rem;
}

%placeholder-fonts {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 3rem;
}

.placeholder {
  @extend %placeholder-fonts;
}

.placeholder-inactive {
  @extend %placeholder-fonts;
  color: $light-gray-500;
}

.text-label {
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 3rem;
}

.text-center {
  text-align: center;
}

.typography-doc {
  &-th {
    padding: 1rem;
    text-align: left;

    &:not(:last-child) {
      border-right: 1px solid;
    }
  }

  &-tr {
    border: 1px solid;
  }

  &-description {
    display: flex;
    width: max-content;

    &-name {
      color: $blue-400;
      margin-right: 1rem;
    }

    &-value {
      color: $yellow;
    }
  }
}

.inactive-font {
  color: $light-gray-500;
}
