.error {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
  }

  &-sign {
    font-size: 20rem;
    font-weight: 300;
    line-height: 27.3rem;
    opacity: 0.3;
  }

  &-title {
    font-size: 5rem;
    font-weight: 300;
    line-height: 6.8rem;
  }

  &-description {
    font-size: 2rem;
    line-height: 2.7rem;
    margin-top: 1rem;
  }
}
