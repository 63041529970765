.integrations {
  &-ul {
    list-style: disc;
    padding-left: 2rem;
  }

  &-li {
    left: 1rem;
    margin-bottom: 1.2rem;
    padding-left: 1rem;
    position: relative;
  }

  &-description {
    background-color: $white;
    border-radius: 10px;
    padding: 2rem;

    @include respond(tab-port) {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      text-align: center;
    }

    &-container {
      background-color: $white;
      border-radius: 10px;
      padding: 3rem 2rem;

      @include respond(phone) {
        margin: 1.5rem 0 0;
      }
    }

    &-text {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;

    @include respond(tab-port) {
      margin: 0 1.5rem;
    }
  }

  &-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;

    @include respond(phone) {
      flex-direction: column-reverse;
      margin-top: 2rem;
    }

    &-button {
      background: $primary-color;
      display: none;
      padding: 0.7rem 1.7rem;
      text-decoration: none;

      @include respond(tab-port) {
        align-items: center;
        display: flex;
      }
      @include respond(phone) {
        justify-content: center;
        padding: 1.2rem;
        width: 100%;
      }

      &-icon {
        height: 1.6rem;
        margin-left: 1rem;
        width: 1.6rem;

        path {
          stroke: $white;
        }
      }
    }

    &-learn-more-btn {
      @include respond(phone) {
        margin-top: 2rem;
      }
    }
  }

  &-tag {
    &-container {
      align-items: center;
      background: $background-gray-l2;
      border-radius: 30px;
      display: flex;
      margin-left: 1rem;
      padding: 0.3rem 0.7rem;
      width: fit-content;
    }

    &-text {
      margin-left: 0.5rem;
      text-transform: uppercase;
    }
  }
}
