/* stylelint-disable selector-class-pattern */
.MuiInputBase-root {
  border-bottom: 1px solid #dddedf !important;
  color: $body-color !important;
  font-family: "Avenir", sans-serif !important;
  font-size: 1.6rem !important;
  font-weight: 900 !important;
  line-height: 3rem !important;
}

.MuiFormControl-root {
  margin-bottom: 3rem !important;
}

.MuiFormHelperText-root {
  color: #ff6262 !important;
  font-family: "Avenir", sans-serif !important;
  font-size: 1.6rem !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  line-height: 2.6rem !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #00729d !important;
}

.MuiPickersDay-daySelected {
  background-color: #00729d !important;
}

.MuiButton-textPrimary {
  color: #00729d !important;
  font-family: "Avenir", sans-serif !important;
  font-size: 1.2rem !important;
  line-height: 2.6rem !important;
}

.MuiInput-underline::before {
  border-bottom: 1px solid #dddedf !important;
  transition: none !important;
}

.MuiInput-underline::after {
  border-bottom: 1px solid #dddedf !important;
  transition: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: 1px solid #dddedf !important;
}

.MuiTypography-colorPrimary {
  color: #00729d !important;
}

.MuiTypography-subtitle1 {
  font-family: "Avenir", sans-serif !important;
  font-size: 1.6rem !important;
  line-height: 2.6rem !important;
}

.MuiPickersYear-yearSelected {
  font-family: "Avenir", sans-serif !important;
  font-size: 1.6rem !important;
  line-height: 2.6rem !important;
}

.MuiTypography-body2 {
  font-family: "Avenir", sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 2.6rem !important;
}

.MuiTypography-body1 {
  font-family: "Avenir", sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 2.6rem !important;
}

.MuiTypography-caption {
  font-family: "Avenir", sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 2.6rem !important;
}

.MuiSvgIcon-root {
  font-size: 2rem !important;
}

.MuiTableContainer-root {
  overflow-x: visible;
}

.MuiTableCell-body {
  border-bottom: 5px solid $white;
  border-radius: 4px;
  border-right: 2px solid $white;
  padding: 0;
  position: relative;
}

.MuiTableCell-head {
  background-color: $white;
  border-bottom: 5px solid $white;
  border-right: 2px solid $white;
}
