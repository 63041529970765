.split-screen-layout-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.left {
  display: flex;
  flex-basis: 40%;

  @include respond(tab-port) {
    flex-basis: auto;
    flex-direction: column;
  }

  &-container {
    margin: auto;
    padding: 0 5rem 0 0;

    @include respond(tab-port) {
      margin-top: 4rem;
      padding: 3rem;
    }
  }

  &-title {
    color: $white;
    font-family: $font-family-main;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 2.5rem;
    mix-blend-mode: normal;

    @include respond(tab-port) {
      font-weight: 900;
      line-height: 3.8rem;
      text-align: center;
    }
  }

  &-description {
    color: $white;

    @include respond(tab-port) {
      font-weight: normal;
      text-align: center;
    }
  }

  &-logo {
    align-self: self-start;
    margin: 5rem 0 0 3rem;
    width: 10rem;

    @include respond(tab-port) {
      align-self: center;
      width: auto;
    }

    @include respond(phone) {
      margin: 5rem 0 0;
    }
  }
}

.right {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
  justify-content: center;

  @include respond(tab-port) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4rem 3rem 0;
  }

  &-error {
    color: $alert-color;
    font-weight: normal;
    margin-bottom: 1rem;

    &-btn {
      background: none;
      border-bottom: 1px solid;
      cursor: pointer;
      font-weight: bold;
      margin-left: 0.5rem;
    }
  }

  &-message {
    margin-bottom: 3rem;
    width: 70%;

    @include respond(tab-port) {
      width: 100%;
    }

    &-gray {
      color: $gray;
      margin-bottom: 3rem;
      width: 70%;

      @include respond(tab-port) {
        width: 100%;
      }
    }
  }

  &-field-info {
    font-style: italic;
    margin-bottom: 1.5rem;

    &-birthday {
      margin-top: -2rem;
    }

    &-link {
      color: $primary-color;
      cursor: pointer;
      font-weight: bold;
    }

    &-container {
      margin: 3rem 0 5rem;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 70%;

    @include respond(tab-port) {
      width: 100%;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      @include respond(tab-port) {
        display: block;
      }
    }

    &-field {
      display: flex;
      flex-direction: column;
      width: 48%;

      @include respond(tab-port) {
        width: 100%;
      }

      &-password {
        position: relative;

        &-container {
          width: 49%;

          @include respond(phone) {
            width: 100%;
          }

          &-left {
            margin-right: 2rem;
          }
        }

        &-icon {
          cursor: pointer;
          position: absolute;
          right: 2rem;
          top: 0;
        }
      }

      &-passwords {
        display: flex;

        @include respond(phone) {
          display: block;
        }
      }

      &-error {
        margin-bottom: 0;
        margin-bottom: 3rem;
        margin-top: -2.6rem;
      }
    }

    &-input {
      border: none;
      border-bottom: 1px solid $input-border-color;
      margin-bottom: 3rem;
      width: 100%;

      &-alert {
        border-color: $alert-color;
        color: $alert-color;
      }

      &:disabled {
        background-color: $white;
      }
    }

    &-btn {
      align-self: flex-start;
      letter-spacing: 0.1rem;
      margin-bottom: 2rem;
      margin-right: 2rem;
      padding: 1.6rem 2.2rem;

      @include respond(tab-port) {
        align-self: initial;
        margin-right: 0;
      }

      &-container {
        @include respond(tab-port) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}
