.managers {
  &-container {
    padding-top: 3rem;

    @include respond(tab-port) {
      padding-top: 0;
    }

    &-inner {
      width: 118rem;
    }
  }

  &-header {
    align-items: center;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
    display: flex;
    padding: 3rem;

    @include respond(tab-port) {
      display: none;
    }

    &-icon {
      height: 5rem;
      margin-right: 2rem;
      stroke: $light-gray-400;
      width: 5rem;
    }
  }

  &-description {
    margin: 3rem 0 2rem;

    @include respond(tab-port) {
      display: none;
    }

    &-table {
      @include respond(tab-port) {
        padding: 0 2rem;
      }
    }
  }

  &-btn {
    align-self: flex-end;
    bottom: 4rem;
    padding: 1.6rem 2.2rem;
    position: fixed;
    right: 2.4rem;
    z-index: $z-index-side-bar;
  }
}
