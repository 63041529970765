%button-main-font {
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.7rem;
}

%button-secondary-font {
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.9rem;
}

%button-small-font {
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.4rem;
}

.primary-btn {
  @extend %button-main-font;
  background: $primary-color;
  border: none;
  border-radius: 100px;
  box-shadow: 0 6px 20px rgba($primary-color, 0.35);
  color: $white;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;

  &:hover {
    background: $primary-hover-color;
    box-shadow: 0 6px 30px rgba($primary-color, 0.35);
  }

  &:active {
    background: $primary-color;
    box-shadow: inset 0 4px 10px rgba($black, 0.2);
    transform: translateY(0.2rem);
  }

  &:disabled {
    background: rgba($primary-color, 0.3);
    border: none;
    box-shadow: none;
    cursor: not-allowed;
    transform: none;
  }
}

.secondary-btn {
  @extend %button-secondary-font;
  background: $tertiary-color;
  border-radius: 51px;
  color: $white;
  cursor: pointer;
}

.tertiary-btn {
  background: none;
  border: 2px solid $primary-color;
  border-radius: 5rem;
  color: $primary-color;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.7rem;
  padding: 1.4rem 2.2rem;
}

.tertiary-btn-small {
  background: none;
  border: 1px solid $primary-color;
  border-radius: 5rem;
  color: $primary-color;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.9rem;
  padding: 0.7rem 1.7rem;

  @include respond(phone) {
    font-size: 1.6rem;
    padding: 1.2rem 2rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &-alert {
    border-color: $alert-color;
    color: $alert-color;
  }

  &-main {
    background: $primary-color;
    border: none;
    color: $white;

    &-alert {
      background-color: $alert-color;
    }
  }
}

.toggle-button {
  &-switch {
    display: inline-block;
    height: 2.8rem;
    position: relative;
    width: 5.1rem;

    &-slider {
      border: 2px solid $light-gray-300;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.4s;

      /* For round sliders */
      &-round {
        border-radius: 2.8rem;
      }

      &-round::before {
        border-radius: 50%;
      }
    }

    &-slider::before {
      background-image: url("../../img/GrayX.svg");
      background-position: center;
      content: "";
      height: 2.1rem;
      left: 0.15rem;
      position: absolute;
      top: 0.15rem;
      transition: 0.4s;
      width: 2.1rem;
    }

    input {
      height: 0;
      opacity: 0;
      width: 0;
    }

    input:checked + .toggle-button-switch-slider::before {
      background-image: url("../../img/BlueCheck.svg");
      background-position: center;
      transform: translateX(2.3rem);
    }
  }
}

%learn-more-button-base {
  @extend %button-secondary-font;
  align-items: center;
  border-bottom: 1px solid $gray;
  justify-content: space-between;
  text-decoration: none;
  width: 9.5rem;
}

.learn-more-button {
  @extend %learn-more-button-base;
  display: flex;
  padding-bottom: 0.2rem;

  &-inline {
    @extend %learn-more-button-base;
    border-bottom: 1px solid $primary-color;
    color: $primary-color;
    display: inline-flex;
    font-size: 1.6rem;
    padding-bottom: 0;
    width: 10.5rem;

    svg path {
      stroke: $primary-color;
    }
  }
}

.tips-getting-started-button {
  @extend %learn-more-button-base;
  display: inline-flex;
  font-size: 1.6rem;
  padding-bottom: 0;
  width: 20rem;
}

.text-button {
  background: none;
  color: $gray;
  cursor: pointer;
  font-family: "Avenir", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
}
