@keyframes appearing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes linkUnderlineToTheLeftHidden {
  0% {
    height: 0.5rem;
    width: 100%;
  }

  95% {
    height: 0.5rem;
    width: 0%;
  }

  100% {
    height: 0;
    width: 0%;
  }
}
