.configuration {
  &-container {
    display: flex;
    padding: 3rem 13rem;

    @include respond(phone) {
      justify-content: center;
      padding: 1.5rem;
    }

    &-inner {
      @include respond(phone) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &-title {
    margin-bottom: 1.4rem;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &-card {
    &-container {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      color: $btn-text-color;
      margin-bottom: 2rem;
      margin-right: 2rem;
      text-decoration: none;
      width: 38rem;

      @include respond(phone) {
        margin-right: 0;
        width: 35rem;
      }

      &-tappable {
        cursor: pointer;
      }
    }

    &-link {
      height: 2.4rem;
    }

    &-button {
      padding: 0.7rem 3rem;

      &-access-history {
        padding: 1rem 3rem;
        text-decoration: none;
      }
    }

    &-line {
      align-items: center;
      display: flex;
      height: 9rem;
      justify-content: space-between;
      padding: 3rem;
    }
  }

  &-details {
    &-container {
      padding: 3rem 13rem 0;
    }

    &-rules {
      &-popup {
        &-container {
          background-color: $background-color-gray;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 3rem;
        }
      }

      &-container {
        margin-bottom: 20rem;
        margin-top: 3rem;
      }

      &-name {
        margin-bottom: 1.4rem;
      }

      &-list {
        border-radius: 10px;
        box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      }
    }
  }

  &-header {
    &-img {
      margin-right: 3rem;
      stroke: var(--app-color);
    }

    &-container {
      background: $white;
      border-radius: 10px;
      box-shadow: 0 10px 34px rgba($light-gray-400, 0.1);
      width: 118rem;

      &-popup {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    &-main {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 3rem 3.5rem;
    }

    &-title-sign {
      background: rgba($body-color, 0.1);
      border-radius: 15px;
      margin-left: 1.2rem;
      padding: 0.3rem 1rem;
    }
  }

  &-rule {
    &-container {
      background: $white;
      padding: 4rem 3rem;
      width: 118rem;

      &:not(:last-child) {
        border-bottom: 2px solid rgba($body-color, 0.1);
      }

      &-popup {
        width: 112rem;
      }
    }
  }

  &-button {
    &-container {
      border: 2px solid rgba($black, 0.1);
      border-radius: 51px;
      padding: 0.9rem 1.7rem;
      width: fit-content;
    }
  }

  &-popup {
    &-btn {
      margin: 5rem 0 4rem;
      padding: 0.7rem 1.7rem;

      &-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.cancel {
  &-container {
    background-color: $white;
    border-radius: 10px;
    margin: auto;
    padding: 3rem 1rem;
  }

  &-title {
    margin: 0 8rem;
    text-align: center;
    width: 32rem;
  }

  &-info {
    align-items: center;
    border-bottom: 2px solid rgba($body-color, 0.1);
    border-top: 2px solid rgba($body-color, 0.1);
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    padding: 2rem;

    &-body {
      font-weight: 500; // No font listed in "Bcc Library"

      &-bottom {
        color: rgba($black, 0.5);
      }
    }
  }

  &-btn {
    background: $tertiary-color;
    box-shadow: none;
    padding: 1.6rem 2.2rem;
    text-align: center;
    transition: all 0.3s;

    &:hover {
      background: $tertiary-color;
      box-shadow: none;
    }
  }
}
