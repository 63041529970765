*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: $font-family-main;
  margin: 0;
  padding: 0;
}

:root {
  --app-color: #f57f20;
  font-size: $font-size-root;
}

body {
  background-color: $body-bg;
  color: $body-color;
  font-family: $font-family-main;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  margin: 0;
}

p {
  @include text;
}

// Links
a {
  color: $link-color;
  text-decoration: $link-decoration;
}

.main-container {
  position: relative;
}

.app-body {
  background: $background-color-gray;
  min-height: 100vh;

  @include respond(tab-port) {
    min-height: 89vh;
  }

  &-side-bar {
    position: relative;
    @include respond(phone) {
      position: unset;
    }
  }

  &-hidden {
    @include respond(phone) {
      display: none;
      overflow: hidden;
      position: fixed;
    }
  }
}

.popup-background {
  align-items: flex-start;
  background-color: rgba($black, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: $z-index-pop-up;
  @supports (
    (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))
  ) {
    backdrop-filter: blur(6px);
    background-color: rgba($black, 0.1);
  }
}

// Margins
//margin-top
.margin-top-small {
  margin-top: 3rem;
}
//margin-bottom
.margin-bottom-medium {
  margin-top: 5rem;
}

.margin-bottom-small {
  margin-bottom: 2rem;
}

.margin-bottom-very-small {
  margin-bottom: 1rem;
}
//margin-right
.margin-right-small {
  margin-right: 3rem;
}

.margin-right-smaller {
  margin-right: 1rem;
}

.margin-right-very-small {
  margin-right: 0.5rem;
}
//margin-left
.margin-left-medium {
  margin-left: 6rem;
}

.margin-left-small {
  margin-left: 3rem;
}

.margin-left-very-small {
  margin-left: 0.5rem;
}
//margin-left-right
.margin-left-right-small {
  margin-left: 3rem;
  margin-right: 3rem;
}

.margin-left-right-smaller {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

// Paddings
.padding-bottom-large {
  padding-bottom: 20rem;
}

.padding-top-small {
  padding-top: 2.4rem;
}

.padding-bottom-small {
  padding-bottom: 2rem;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-display {
  display: flex;
}

.center-align {
  display: flex;
  justify-content: center;
}

.flex-end-align {
  display: flex;
  justify-content: flex-end;
}

.flex-with-space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.center-align-as-row {
  align-items: center;
  display: flex;
}

.center-align-as-column {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.align-as-column {
  display: flex;
  flex-direction: column;
}

.uppercase {
  text-transform: uppercase;
}

.alert {
  color: $alert-color !important;
}

.svg-icon {
  &-large {
    height: 5rem;
    width: 5rem;

    @include respond(phone) {
      height: 4rem;
      width: 4rem;
    }
  }

  &-documentation {
    &-background {
      background-color: $black;
    }

    &-size {
      height: 3.5rem;
      padding: 0.4rem;
      width: 4rem;
    }
  }
}

.tooltip {
  display: inline-block;
  position: relative;

  &-upper {
    &-container {
      background-color: $white;
      border: 2px solid rgba($black, 0.1);
      border-radius: 5px;
      position: absolute;
      width: fit-content;

      &-inner {
        padding: 1.5rem;
        position: relative;
        width: 16rem;

        &::after {
          background-color: #fff;
          border-bottom-style: solid;
          border-bottom-width: 2px;
          border-color: rgba($black, 0.1);
          border-right-style: solid;
          border-right-width: 2px;
          bottom: -9px;
          content: " ";
          display: inline-block;
          height: 1.5rem;
          left: 46%;
          position: absolute;
          transform: rotate(45deg);
          width: 1.5rem;
        }
      }
    }

    &-learn-more {
      margin: 1rem 0;
    }
  }
}

.dialog {
  &-box {
    visibility: hidden;
  }

  &-tags {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-container {
      background-color: $white;
      border: 2px solid rgba($black, 0.1);
      border-radius: 5px;
      position: absolute;
      width: fit-content;

      &-inner {
        padding: 1.5rem;
        position: relative;

        &::after {
          background-color: #fff;
          border-color: rgba($black, 0.1);
          border-left-style: solid;
          border-left-width: 2px;
          border-top-style: solid;
          border-top-width: 2px;
          content: " ";
          display: inline-block;
          height: 1.5rem;
          left: 46%;
          position: absolute;
          top: -9px;
          transform: rotate(45deg);
          width: 1.5rem;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.6;
}

.tooltip:hover .dialog-tags-container {
  visibility: visible;
}

.tooltip:hover .dialog-tags-container {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 33px;
  transform: translate(-50%);
  width: max-content;
  z-index: $z-index-header-drop-down;
}

.tooltip:hover .tooltip-upper-container {
  visibility: visible;
}

.tooltip:hover .tooltip-upper-container {
  bottom: 5rem;
  display: inline-block;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: max-content;
  z-index: $z-index-header-drop-down;

  @include respond(phone) {
    bottom: 8rem;
  }
}

.tooltip:hover .integrations-tag-upper-tooltip {
  bottom: 3.5rem;
}

.element-tab-port {
  &-hidden {
    @include respond(tab-port) {
      display: none;
    }
  }

  &-visible {
    display: none;
    @include respond(tab-port) {
      display: block;
    }
  }
}
