.registration {
  // ExpiredInvitation Component
  &-expired-invitation {
    align-items: center;
    background-color: $background-color-gray;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    &-invalid-invitation-body {
      text-align: center;
    }

    &-img {
      margin-bottom: 3rem;
    }

    &-footer {
      bottom: 0;
      position: absolute;
    }
  }

  // Success component
  &-success {
    padding-bottom: 6rem;
    padding-top: 4rem;
    text-align: center;

    &-text {
      text-align: center;
    }

    &-img-link {
      height: 4rem;
      width: 12rem;
    }

    &-brilliant-logo {
      margin: 3rem 0 4rem;
    }

    &-heading {
      margin-bottom: 4rem;
    }

    &-key-logo {
      height: 8rem;
      margin-bottom: 6rem;
      opacity: 0.2;
      width: 9rem;
    }

    &-button-link {
      background: $primary-color;
      border-radius: 51px;
      box-shadow: 0 6px 20px rgba($primary-color, 0.35);
      color: $white;
      height: 6rem;
      margin-top: 4rem;
      padding: 1.5rem;
      text-decoration: none;
      width: 42rem;

      @include respond(tab-port) {
        width: 28rem;
      }

      &:hover {
        background: $primary-hover-color;
        box-shadow: 0 6px 30px rgba($primary-color, 0.35);
      }

      &:active {
        background: $primary-color;
        box-shadow: inset 0 4px 10px rgba($black, 0.2);
        transform: translateY(0.2rem);
      }
    }
  }
}

.terms-conditions {
  &-container {
    background: $background-color-gray;
    border: 1px solid $light-gray-400;
    border-radius: 5px;
    padding: 2rem;
  }

  &-label {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    width: 70%;
  }

  &-field-container {
    align-items: flex-start;
    display: flex;
  }

  &-checkbox {
    appearance: none;
    background-color: $background-color-gray;
    border: 1px solid $light-gray-400;
    display: inline-block;
    height: 2.5rem;
    margin-right: 1rem;
    position: relative;
    width: 2.5rem;

    &:checked {
      color: $body-color;

      &::after {
        content: "\2713";
        font-size: 2rem;
        left: 3px;
        position: absolute;
        top: 0;
      }
    }
  }
}
